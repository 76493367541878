<script>
import useVuelidate from '@vuelidate/core'
import {maxLength, required} from '@vuelidate/validators'

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkFormSubmitted from "@components/general/AkFormSubmitted";
import AkTime from "@components/input/AkTime";
import AkInputText from "@components/input/AkInputText";
import AkInputTextArea from "@components/input/AkInputTextArea";
import AkDateTime from "@components/input/AkDateTime";
import AkFileUpload from "@components/input/AkFileUpload";
import AkDialog from "@components/general/AkDialog";
import AkCheckboxButton from "@components/input/AkCheckboxButton";
import AkYesNoButton from "@components/input/AkYesNoButton";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import roleMixin from "@mixins/roleMixin";
import eventMixin from "@mixins/eventMixin";
import dateFormatter from "@mixins/dateFormatter";
import urlMixin from "@mixins/urlMixin";
import imgMixin from "@mixins/imgMixin";
import typeVhConst from "@mixins/const/typeVhConst";

/* SERVICES */
import rolePermissionService from "@services/rolePermissionService";
import forecastSheetService from "@services/forecastSheetService";
import fileService from "@services/fileService";

export default {
  components: {
    AkFormView,
    AkFormSubmitted,
    AkInputTextArea,
    AkTime,
    AkInputText,
    AkDateTime,
    AkFileUpload,
    AkDialog,
    AkCheckboxButton,
    AkYesNoButton
  },
  mixins: [randomRef, eventMixin, dateFormatter, roleMixin, urlMixin, imgMixin, typeVhConst],
  setup() {
    return {v$: useVuelidate()}
  },
  metaInfo() {
    return {
      title: "forecast_sheet.update",
    }
  },
  data() {
    return {
      submitted: false,
      validationSubmitted: false,
      deleteIndex: null,
      filesToShow: [],
      files: null,
      additionalFiles: null,
      current: {
        date: null,
        patrolStart: null,
        curativeStart: null,
        precurativeStart: null,
        forecastObservation: "",
        interventionObservation: "",
        agerId: null,
        uerId: null,
        ceiId: null,
        tel: "",
      },
      forecastRows: [],
      events: [],
    }
  },
  validations() {
    return {
      current: {
        date: {required},
        agerId: {required},
        uerId: {required},
        ceiId: {required},
        tel: {maxLength: maxLength(100)},
        motif: {maxLength: maxLength(5000)},
        forecastObservation: {maxLength: maxLength(5000)},
        interventionObservation: {maxLength: maxLength(5000)},
      },
    }
  },
  mounted() {
    this.getRef().showTotalLoader();

    let p1 = forecastSheetService.findByIdWithRowsForEdit(this.$route.params.id);
    p1.then((data) => {
      this.current = data.forecastSheet;
      this.forecastRows = data.forecastRows;
      this.events = this.getSheetEvents(this.current);
    });

    let p2 = fileService.findAllByForecastSheetId(this.$route.params.id);
    p2.then((data) => this.filesToShow = data);

    let p3 = rolePermissionService.authorizeAccessByPermission(this.permissionConst.forecast.edit);

    Promise.all([p1, p2, p3]).then(() => {
      this.submitted = false;
      this.getRef().hideLoader();
    });
  },
  methods: {
    updateSheet() {
      if (!this.checkForm()) {
        this.getRef().error(this.$t("forecast_sheet.error.update"));
        return;
      }

      if (!this.currentDateFriday) {
        this.current.weekend = false;
        this.current.motif = null;
      }

      this.getRef().showLoader(this.$refs.form);
      let dto = {
        forecastSheet: this.current,
        forecastRows: this.forecastRows,
        validated: this.validationSubmitted
      }
      forecastSheetService.update(dto).then(data => {
        this.saveFiles(data);
        this.goToDetails();
      }).catch(this.displayError);
    },
    saveFiles(data) {
      let formData = new FormData();

      if (this.files && this.files[0]) {
        this.files.forEach(f => formData.append('meteoFiles', f));
      }
      if (this.additionalFiles && this.additionalFiles[0]) {
        this.additionalFiles.forEach(f => formData.append('additionalFiles', f));
      }

      if (this.files && this.files[0] || this.additionalFiles && this.additionalFiles[0]) {
        Promise.all([fileService.createForecastSheetFile(formData, data.forecastSheet.id)]).then();
      }
    },
    goToDetails() {
      let msg = this.$t(this.validationSubmitted ? "forecast_sheet.updated_validated" : "forecast_sheet.updated");
      this.$router.replace({path: `/forecast/${this.current.id}/details`, query: {msg: msg}});
      this.$refs.dialogValidate.hide();
    },
    displayError(e) {
      this.getRef().hideLoader();
      this.getRef().error(this.$t("error." + e.response.data.message));
      this.submitted = false;
      this.validationSubmitted = false;
      this.$refs.dialogValidate.hide();
    },
    updateAndValidate() {
      this.validationSubmitted = true;
      this.updateSheet();
    },
    deleteSchedule() {
      this.getRef().resetMessages();
      this.forecastRows.splice(this.deleteIndex, 1);
      this.$refs.dialogDelete.hide();
    },
    openDeleteDialog(index) {
      this.deleteIndex = index;
      this.$refs.dialogDelete.show();
    },
    openValidationDialog() {
      if (!this.checkForm()) return;
      this.$refs.dialogValidate.show();
    },
    checkForm() {
      this.getRef().resetMessages();
      this.submitted = true;
      this.v$.$touch();
      return !this.v$.$error;
    },
    addRow() {
      this.forecastRows.push({
        hour: "0:00",
        airTemp: null,
        groundTemp: null,
        humidity: null,
      });
    },
    checkHour(rowIndex) {
      let hour = this.forecastRows[rowIndex].hour;
      this.forecastRows[rowIndex].hour = this.formatHourFromTextInput(hour);
    },
    checkAirTemp(rowIndex) {
      let airTemp = this.forecastRows[rowIndex].airTemp;
      this.forecastRows[rowIndex].airTemp = this.checkBetween(airTemp, -100.0, 100.0);
    },
    checkGroundTemp(rowIndex) {
      let groundTemp = this.forecastRows[rowIndex].groundTemp;
      this.forecastRows[rowIndex].groundTemp = this.checkBetween(groundTemp, -100.0, 100.0);
    },
    checkHumidityRate(rowIndex) {
      let humidity = this.forecastRows[rowIndex].humidity;
      this.forecastRows[rowIndex].humidity = this.checkBetween(humidity, 0.0, 100.0);
    },
    roundAirTemp(rowIndex) {
      let row = this.forecastRows[rowIndex];
      if (row.airTemp) row.airTemp = row.airTemp.toFixed(1);
    },
    roundGroundTemp(rowIndex) {
      let row = this.forecastRows[rowIndex];
      if (row.groundTemp) row.groundTemp = row.groundTemp.toFixed(1);
    },
    roundHumidityRate(rowIndex) {
      let row = this.forecastRows[rowIndex];
      if (row.humidity) row.humidity = row.humidity.toFixed(1);
    },
    focusInput(event) {
      let input = event.target.querySelectorAll('input')[0];
      if (input) input.focus();
    },
    deleteFile(file) {
      this.filesToShow = this.filesToShow.filter(f => f.id !== file.id);
      fileService.deleteForecastSheetFile(file).catch(e => {
        this.getRef().error(this.$t("error." + e.response.data.error));
      });
    },
    getUrl(file) {
      return this.generateUrl('/file/content/' + file.id);
    },
  },
  computed: {
    dateDisplay() {
      return this.prettyFormatDateHour(this.current.date);
    },
    agerList() {
      return [{id: this.current.agerId, label: this.current.agerLabel}]
    },
    uerList() {
      return [{id: this.current.uerId, label: this.current.uerLabel}]
    },
    ceiList() {
      return [{id: this.current.ceiId, label: this.current.ceiLabel}]
    },
    riName() {
      return this.current.creatorFirstName + " " + this.current.creatorLastName;
    },
    currentDateFriday() {
      return this.findDayOfWeek(this.current.date) === 5;
    },
    meteoFilesToShow() {
      return this.filesToShow.filter(file => file.type === 'METEO');
    },
    additionalFilesToShow() {
      return this.filesToShow.filter(file => file.type === 'ADDITIONAL_FILE');
    }
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('forecast_sheet.update')">
    <template v-slot:form>
      <AkFormSubmitted :submitted=submitted reference="form">
        <div class="form-row">
          <AkDateTime v-model="current.date"
                      :label="$t('date')"
                      :showTime=false
                      :validator="v$.current.date"
                      class-name="col-md-3"/>
          <AkInputText v-model="current.agerLabel" :disabled="true" :label="$t('ager_label')" class-name="col-md-3"/>
          <AkInputText v-model="current.uerLabel" :disabled="true" :label="$t('uer_label')" class-name="col-md-3"/>
          <AkInputText v-model="current.ceiLabel" :disabled="true" :label="$t('cei_label')" class-name="col-md-3"/>
        </div>
        <div v-if="current.typeVh" class="form-row">
          <AkCheckboxButton v-model="current.typeVh"
                            :disabled="true"
                            :label="$t('type_vh_label')"
                            :multiple="false"
                            :options=typeVhConst
                            :submitted="this.submitted"
                            :validator="v$.current.typeVh"
                            class-name="col-md-6"/>
        </div>
        <div class="form-row">
          <AkInputText
              v-model="riName"
              :disabled="true"
              :label="$t('forecast_sheet.creator_name')"
              class-name="col-md-6"/>
          <AkInputText
              v-model="current.tel"
              :label="$t('forecast_sheet.creator_tel')"
              :submitted=this.submitted
              :validator="v$.current.tel"
              class-name="col-md-6"
              placeholder=" "/>
        </div>
        <h5 class="mt-2">{{ $t('forecast_sheet.weather_forecast') }}</h5>
        <span class="btn btn-xs btn-inverse-primary"
              style="margin-bottom: 10px"
              @click="addRow">
          <i class="fe fe-plus"/>
          {{ $t('forecast_row.add_row') }}
        </span>
        <div class="form-row" style="padding-bottom: 20px">
          <div class="col-lg-12">
            <div class="table-responsive forecast-table">
              <table>
                <thead>
                <tr>
                  <th>{{ $t('forecast_sheet.hours') }}</th>
                  <th v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.hour" maxlength="5" size="5" @focusout="checkHour(index)"/>
                    <span v-if=this.canDeleteForecast(current) class="btn btn-xs btn-inverse-danger"
                          @click="openDeleteDialog(index)">
                        <i class="fe fe-trash"/>
                      </span>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{ $t('forecast_row.air_temp') }}</td>
                  <td v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.airTemp" max="100" min="-100" step="0.1" type="number"
                           @focusout="roundAirTemp(index)" @input="checkAirTemp(index)"/>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('forecast_row.ground_temp') }}</td>
                  <td v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.groundTemp" max="100" min="-100" step="0.1" type="number"
                           @focusout="roundGroundTemp(index)" @input="checkGroundTemp(index)"/>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('forecast_row.humidity') }}</td>
                  <td v-for="(row, index) in forecastRows" :key="row.order" @click="focusInput($event)">
                    <input v-model="row.humidity" max="100" min="0" step="0.1" type="number"
                           @focusout="roundHumidityRate(index)" @input="checkHumidityRate(index)"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-row">
          <AkInputTextArea
              v-model="current.forecastObservation"
              :label="$t('forecast_sheet.forecast_observation')"
              :submitted=this.submitted
              :validator="v$.current.forecastObservation"
              class-name="col-md-12"
              placeholder=" "/>
        </div>

        <h5 class="mt-2">{{ $t('forecast_sheet.files') }}</h5>
        <template v-if="meteoFilesToShow.length > 0">
          <div class="form-row">
            <template v-for="file in meteoFilesToShow" :key="file.id">
              <div v-if="file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=getUrl(file) style="margin: 5px 0 5px 5px;" target="_blank">
                  <img :alt=file.name :src=getUrl(file) style="min-height: 100%; max-width: 100%;"/>
                </a>
                <i class="pi pi-trash p-1 trash-can" @click="deleteFile(file)"/>
              </div>
            </template>
          </div>
          <div class="form-row">
            <template v-for="file in meteoFilesToShow" :key="file.id">
              <div v-if="!file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                <a :href=getUrl(file) class="card file-card">
                  <img :alt="getThumbnailAlt(file.mimetype)" :src="getThumbnail(file.mimetype)" style="max-width: 30px;"/>
                  <p style="margin-left: 5px; margin-right: 5px;">{{ file.name }}</p>
                </a>
                <i class="pi pi-trash p-1 trash-can" @click="deleteFile(file)"/>
              </div>
            </template>
          </div>
        </template>
        <div class="form-row">
          <AkFileUpload v-model="files"
                        class-name="col-md-4"/>
        </div>
        <h5 class="mt-2">{{ $t('forecast_sheet.intervention_forecast') }}</h5>
        <div class="form-row">
          <div class="col-md-6">
            <div class="form-row">
              <AkTime v-model="current.patrolStart"
                      :label="$t('forecast_sheet.patrol_start')"
                      class-name="col-md-12"
                      placeholder=" "/>
              <AkTime v-model="current.precurativeStart"
                      :label="$t('forecast_sheet.precurative_start')"
                      class-name="col-md-12"
                      placeholder=" "/>
              <AkTime v-model="current.curativeStart"
                      :label="$t('forecast_sheet.curative_start')"
                      class-name="col-md-12"
                      placeholder=" "/>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-row">
              <AkInputTextArea
                  v-model="current.interventionObservation"
                  :label="$t('forecast_sheet.intervention_observation')"
                  :rows=9
                  :submitted=this.submitted
                  :validator="v$.current.interventionObservation"
                  class-name="col-md-12"
                  placeholder=" "/>
            </div>
          </div>
        </div>
      
        <div v-if="currentDateFriday">
          <h5 class="mt-2">{{ $t('forecast_sheet.weekend_activation') }}</h5>
          <div class="form-row">
            <AkYesNoButton v-model="current.weekend" 
              :label="$t('forecast_sheet.weekend')"
              :multiple="false" 
              class-name="col-md-2"/>
          </div>
          <div class="form-row">
            <AkInputTextArea v-model="current.motif" 
              :label="$t('forecast_sheet.weekend_motif')"
              class-name="col-md-12" 
              placeholder=" "/>
          </div>
          <h5 class="mt-2">{{ $t('forecast_sheet.additional_files') }}</h5>
          <template v-if="additionalFilesToShow.length > 0">
            <div class="form-row">
              <template v-for="file in additionalFilesToShow" :key="file.id">
                <div v-if="file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                  <a :href=getUrl(file) style="margin: 5px 0 5px 5px;" target="_blank">
                    <img :alt=file.name :src=getUrl(file) style="min-height: 100%; max-width: 100%;"/>
                  </a>
                  <i class="pi pi-trash p-1 trash-can" @click="deleteFile(file)"/>
                </div>
              </template>
            </div>
            <div class="form-row">
              <template v-for="file in additionalFilesToShow" :key="file.id">
                <div v-if="!file.mimetype.includes('image/')" class="col-md-4" style="display: flex;">
                  <a :href=getUrl(file) class="card file-card">
                    <img :alt="getThumbnailAlt(file.mimetype)" :src="getThumbnail(file.mimetype)" style="max-width: 30px;"/>
                    <p style="margin-left: 5px; margin-right: 5px;">{{ file.name }}</p>
                  </a>
                  <i class="pi pi-trash p-1 trash-can" @click="deleteFile(file)"/>
                </div>
              </template>
            </div>
          </template>
        </div>
        <div class="form-row">
          <AkFileUpload v-model="additionalFiles"
                        class-name="col-md-12"/>
        </div>
        <h5 class="mt-2">{{ $t('forecast_sheet.history') }}</h5>
        <div class="form-row">
          <Timeline :value="events">
            <template #opposite="slotProps">
              <small class="p-text-secondary">{{ slotProps.item.dateDisplay }}</small>
            </template>
            <template #content="slotProps">
              {{ slotProps.item.name }}
            </template>
          </Timeline>
        </div>
        <div v-if=!roleLoading class="float-right">
          <button v-if=this.canValidateForecast(current)
                  class="btn btn-success"
                  @click=openValidationDialog()>
            {{ $t('validate_send') }}
          </button>
          <button v-if=this.canEditForecast(current)
                  class="btn btn-primary"
                  style="margin-left: 10px" @click=updateSheet()>
            {{ $t('save_draft') }}
          </button>
        </div>
      </AkFormSubmitted>
    </template>

    <template v-slot:extra>
      <AkDialog ref="dialogValidate"
                :cancel-label="$t('no')"
                :title="$t('forecast_sheet.validate_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.updateAndValidate()">
        <div class="form-row">
          <AkInputTextArea v-model="current.validationComment"
                           :label="$t('forecast_sheet.comment')"
                           :submitted=this.submitted
                           class-name="col-12"
                           placeholder=" "/>
        </div>
      </AkDialog>

      <AkDialog ref="dialogDelete"
                :auto-hide-on-validate="true"
                :cancel-label="$t('no')"
                :title="$t('forecast_row.delete_dialog')"
                :validate-label="$t('yes')"
                width="450px"
                @validate="this.deleteSchedule()">
        <div class="confirmation-content">
          <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem"/>
          <span>{{ $t('forecast_row.confirm_delete') }}</span>
        </div>
      </AkDialog>
    </template>
  </AkFormView>
</template>

<style>
.trash-can {
  cursor: pointer;
  border-radius: 0 .25rem .25rem 0;
  margin: 5px 5px 5px 0;
  border: 1px solid #e7eaf3;
  border-left-width: 0;
}

.trash-can:hover {
  color: #40b4e5;
  background-color: #40b4e520;
}

.file-card {
  align-items: center;
  flex-direction: row;
  padding: 5px 5px;
  margin: 5px 0;
  border-radius: .25rem 0 0 .25rem;
  width: -webkit-fill-available;
}
</style>